import { Link } from "react-router-dom";
import { useState } from "react";
import * as F from "react-figura";

export default function Simple() {
	const [inputData, setInputData] = useState([]);

	function someApiCall(data) {
		var result = Object.keys(data).map((key) => [key + " = ", data[key]]);
		setInputData(result);
		console.log(data);
	}

	return (
		<div className="example-container">
			<div className="example-button-container">
				<div className="example-button-fixed">
					<Link to={"/"}>
						<div className="example-button top-button">
							<span>Home</span>
						</div>
					</Link>

					<Link to={"/docs"}>
						<div className="example-button bot-button">
							<span>Docs</span>
						</div>
					</Link>

					<Link to={"/huge"}>
						<div className="example-button mid-button">
							<span>Huge</span>
						</div>
					</Link>
				</div>
			</div>

			<F.Figura formID="signup" onSubmit={someApiCall}>
				<F.FiguraTitle>Sign Up Form</F.FiguraTitle>

				<F.FiguraText name="name">
					<F.FiguraLabel>Name:</F.FiguraLabel>
				</F.FiguraText>

				<F.FiguraEmail name="email">
					<F.FiguraLabel>Email:</F.FiguraLabel>
				</F.FiguraEmail>

				<F.FiguraPassword name="password">
					<F.FiguraLabel>Password:</F.FiguraLabel>
				</F.FiguraPassword>

				<F.FiguraConfirmPassword name="confirm">
					<F.FiguraLabel>Confirm:</F.FiguraLabel>
				</F.FiguraConfirmPassword>

				<F.FiguraSubmitBtn>Sign Up</F.FiguraSubmitBtn>
				<F.FiguraResetBtn>Reset</F.FiguraResetBtn>

				<F.FiguraHidden />
			</F.Figura>

			<div className="example-data-output">
				<h2>Data Output:</h2>
				{inputData.map((data, index) => {
					return <p key={index}>{data}</p>;
				})}
			</div>
		</div>
	);
}
