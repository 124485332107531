import { Link } from "react-router-dom";
import * as F from "react-figura";
import { useState } from "react";

export default function Huge() {
	const [inputData, setInputData] = useState([]);

	function someApiCall(data) {
		var result = Object.keys(data).map((key) => [key + " = ", data[key]]);
		setInputData(result);
		console.log(data);
	}

	function customValidation(value) {
		if (value.trim() === "") {
			return { hasError: true, error: "You must fill this field out" };
		} else if (value.includes("pizza")) {
			return { hasError: true, error: "No pizza for you" };
		}
		return { hasError: false, error: "" };
	}

	return (
		<div className="example-container">
			<div className="example-button-container">
				<div className="example-button-fixed">
					<Link to={"/"}>
						<div className="example-button top-button">
							<span>Home</span>
						</div>
					</Link>

					<Link to={"/docs"}>
						<div className="example-button bot-button">
							<span>Docs</span>
						</div>
					</Link>

					<Link to={"/simple"}>
						<div className="example-button mid-button">
							<span>Simple</span>
						</div>
					</Link>
				</div>
			</div>
			<F.Figura formID="hugeform" onSubmit={someApiCall}>
				<F.FiguraTitle>Title</F.FiguraTitle>

				<F.FiguraText name="text" validator={customValidation}>
					<F.FiguraLabel>Text:</F.FiguraLabel>
				</F.FiguraText>

				<F.FiguraEmail name="email">
					<F.FiguraLabel>Email:</F.FiguraLabel>
				</F.FiguraEmail>

				<F.FiguraPhone name="phone">
					<F.FiguraLabel>Phone:</F.FiguraLabel>
				</F.FiguraPhone>

				<F.FiguraPassword name="password">
					<F.FiguraLabel>Password:</F.FiguraLabel>
				</F.FiguraPassword>

				<F.FiguraConfirmPassword name="confirm">
					<F.FiguraLabel>Confirm Password:</F.FiguraLabel>
				</F.FiguraConfirmPassword>

				<F.FiguraDate name="date">
					<F.FiguraLabel>Date:</F.FiguraLabel>
				</F.FiguraDate>

				<F.FiguraDateLocal name="datetime">
					<F.FiguraLabel>Date Time Local:</F.FiguraLabel>
				</F.FiguraDateLocal>

				<F.FiguraFile name="file">
					<F.FiguraLabel>File:</F.FiguraLabel>
				</F.FiguraFile>

				<F.FiguraMonth name="month">
					<F.FiguraLabel>Month:</F.FiguraLabel>
				</F.FiguraMonth>

				<F.FiguraNumber name="number">
					<F.FiguraLabel>Number:</F.FiguraLabel>
				</F.FiguraNumber>

				<F.FiguraSelect name="select">
					<F.FiguraLabel>Select:</F.FiguraLabel>
					<option value="">Choose an option...</option>
					<option value="easy">Easy</option>
					<option value="medium">Medium</option>
					<option value="hard">Hard</option>
				</F.FiguraSelect>

				<F.FiguraTextArea name="textarea">
					<F.FiguraLabel>Text Area:</F.FiguraLabel>
				</F.FiguraTextArea>

				<F.FiguraTime name="time">
					<F.FiguraLabel>Time:</F.FiguraLabel>
				</F.FiguraTime>

				<F.FiguraTimeMilitary name="military">
					<F.FiguraLabel>Time Military:</F.FiguraLabel>
				</F.FiguraTimeMilitary>

				<F.FiguraUrl name="url">
					<F.FiguraLabel>Url:</F.FiguraLabel>
				</F.FiguraUrl>

				<F.FiguraWeek name="week">
					<F.FiguraLabel>Week:</F.FiguraLabel>
				</F.FiguraWeek>

				<span>How are you feeling today?</span>
				<F.FiguraButtonGroup name="group">
					<F.FiguraButton>Okay</F.FiguraButton>
					<F.FiguraButton>Good</F.FiguraButton>
					<F.FiguraButton>Bad</F.FiguraButton>
				</F.FiguraButtonGroup>

				<F.FiguraCheckBox name="check">
					<F.FiguraLabel>This is a checkbox</F.FiguraLabel>
				</F.FiguraCheckBox>

				<F.FiguraRadio name="radio">
					<F.FiguraLabel>This is a radio button</F.FiguraLabel>
				</F.FiguraRadio>

				<F.FiguraRange name="range">
					<F.FiguraLabel>Range picker</F.FiguraLabel>
				</F.FiguraRange>

				<F.FiguraColor name="color">
					<F.FiguraLabel>Color picker</F.FiguraLabel>
				</F.FiguraColor>

				<F.FiguraSubmitBtn>Sign Up</F.FiguraSubmitBtn>
				<F.FiguraResetBtn>Reset</F.FiguraResetBtn>

				<F.FiguraHidden />
			</F.Figura>

			<div className="example-data-output">
				<h2>Data Output:</h2>
				{inputData.map((data, index) => {
					return <p key={index}>{data}</p>;
				})}
			</div>
		</div>
	);
}
