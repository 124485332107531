import { MarkdownTable, markdownContent1, markdownContent2 } from "../Utils/FiguraMarkdown";
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import logo from "../assets/figura-logo.svg";
import "highlight.js/styles/github-dark.css";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import React from "react";
import "./Docs.css";

export default function Docs() {
	const navigate = useNavigate();

	const home = () => navigate("/");

	return (
		<div className="markdown-container">
			<div className="markdown-wrapper">
				<div className="logo-wrapper">
					<img src={logo} alt="figura logo" />
				</div>
				<ReactMarkdown rehypePlugins={[rehypeHighlight, rehypeRaw]} plugins={[gfm]} children={markdownContent1} />
				<MarkdownTable />
				<ReactMarkdown rehypePlugins={[rehypeHighlight, rehypeRaw]} plugins={[gfm]} children={markdownContent2} />
			</div>

			<div className="example-button docs-back-button" onClick={home}>
				<span>Home</span>
			</div>
		</div>
	);
}
