import { useNavigate } from "react-router-dom";
import logo from "../assets/figura-logo.svg";
import React from "react";
import "./Home.css";

export default function Home() {
	const navigate = useNavigate();
	const simple = () => navigate("/simple");
	const huge = () => navigate("/huge");
	const docs = () => navigate("/docs");

	return (
		<div className="figura-container">
			<div className="logo-wrapper">
				<img src={logo} alt="figura logo" />
			</div>
			<p>This is an example on how to utilize the react-figura form library.</p>
			<p>To learn more click below to see the documentation or examples</p>

			<div className="figura-button-container">
				<button onClick={docs}>Documentation</button>
				<button onClick={simple}>Simple Example</button>
				<button onClick={huge}>Huge Example</button>
			</div>
		</div>
	);
}
