import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Huge from "./Components/Huge";
import Simple from "./Components/Simple";
import Docs from "./Components/Docs";
import "./App.css";

export default function App() {
	return (
		<div className="app">
			<Router basename="/">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/huge" element={<Huge />} />
					<Route path="/simple" element={<Simple />} />
					<Route path="/docs" element={<Docs />} />
				</Routes>
			</Router>
		</div>
	);
}
